'use client';

/*
 * Good to know:
 * Error boundaries must be Client Components
 * global-error must include html and body tags
 * global-error.tsx is only enabled in production. In development, next.js error overlay will show instead.
 * */
import { AuthLayoutCard, Button } from '@paytome.co/shared';

export default function GlobalError({ reset }: { reset: () => void }) {
  return (
    <html>
      <body>
        <AuthLayoutCard>
          <h2>Something went wrong!</h2>
          <Button onClick={() => reset()}>Try again</Button>
        </AuthLayoutCard>
      </body>
    </html>
  );
}
